.input {
  width: 100%;
  height: 44px;
  border: 1px solid rgba(97, 117, 134, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: 0px 10px;
  font-size: 16px;
  position: relative;
}

.label {
  text-align: start;
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 16px !important;
}

.error {
  color: red;
  font-weight: 500;
  margin: 3px 0px;
  border-color: red;
  font-size: 14px !important;
}
