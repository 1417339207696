.dashboard-root {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #e5e5e5;
}
.dashboard-root .dashboard-content {
  width: 80%;
  margin-left: 20%;
}

@media only screen and (max-width: 960px) {
  .dashboard-root {
    justify-content: center;
  }
  .dashboard-root .dashboard-content {
    width: 100%;
    margin-left: 0%;
  }
}
