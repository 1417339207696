.App {
  min-height: 100vh;
  width: 100%;
  /* display: flex; */
  /* flex: 1; */
  /* background-color: #fff; */
}

.Content {
  display: flex;
  height: calc(100vh - 195px);
  overflow: hidden;
  background-color: #f4f4f1;
  justify-content: center;
  padding-top: 108px;
}
.Content > div {
  height: max-content !important;
}

.Page {
  flex: 1;
}

@font-face {
  font-family: 'VisueltBold';
  src: local('Visuelt'), url('./fonts/VisueltPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'VisueltMedium';
  src: local('Visuelt'), url('./fonts/VisueltPro-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Visuelt';
  src: local('Visuelt'),
    url('./fonts/VisueltPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GraphikLight';
  src: local('Graphik'), url(./fonts/GraphikLight.otf) format('opentype');
}

@font-face {
  font-family: 'Graphik';
  src: local('Graphik'), url(./fonts/GraphikRegular.otf) format('opentype');
}

@font-face {
  font-family: 'GraphikMedium';
  src: local('Graphik'), url(./fonts/GraphikMedium.otf) format('opentype');
}

@font-face {
  font-family: 'GraphikSemiBold';
  src: local('Graphik'), url(./fonts/GraphikSemibold.otf) format('opentype');
}

@font-face {
  font-family: 'GraphikBold';
  src: local('Graphik'), url(./fonts/GraphikBold.otf) format('opentype');
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
