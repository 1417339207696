.main-global-settings-root {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}
.main-global-settings-root .main-account-settings-outlet {
  display: flex;
  justify-content: center;
  width: 80%;
  padding-right: 4%;
  padding-left: 4%;
  padding-top: 4%;
}

@media only screen and (max-width: 800px) {
  .main-global-settings-root .main-account-settings-outlet {
    width: 100%;
    padding-top: 4%;
    padding-right: 1%;
    padding-left: 1%;
  }
}
