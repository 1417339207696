.dropdown-root {
  height: auto;
  background-color: rgb(255, 255, 255);
  position: absolute;
  z-index: 2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 1px 1px 5px 0px rgba(133, 133, 133, 0.75);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(133, 133, 133, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(133, 133, 133, 0.75);
  animation: rotateMenu 600ms ease-in-out forwards;
  transform-origin: top center;
}
.dropdown-root .dropdown-element {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: rgb(255, 255, 255);
  transition: background-color 0.3s;
}
.dropdown-root .dropdown-element:hover {
  background-color: rgb(221, 221, 221);
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
