.allergies-root {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
  }
  .allergies-root .allergies-outlet {
    display: flex;
    justify-content: center;
    width: 80%;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 4%;
  }
  
  @media only screen and (max-width: 800px) {
    .allergies-root .allergies-outlet {
      width: 100%;
      padding-top: 4%;
      padding-right: 1%;
      padding-left: 1%;
    }
  }
  