.input-root {
  border: 1px solid rgb(201, 201, 201);
  background-color: white;
  border-radius: 4px;
  transition: all 0.2s;
  padding-left: 5px;
  width: 100%;
  height: 35px;
  display: flex;
}
.input-root .prefix {
  max-width: 15px;
  max-height: 15px;
  margin-top: auto;
  margin-bottom: auto;
}
.input-root .postfix {
  font-size: 12px;
  text-align: center;
  margin-bottom: auto;
  margin-top: auto;
  padding-right: 2px;
}
.input-root .postfix:hover {
  cursor: pointer;
}
.input-root .input-field {
  width: 100%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  border: none;
}
.input-root .input-field:focus {
  border: none;
  outline: none;
}
.input-root .input-field.isPrefix {
  padding-left: 5px;
}

.input-root:hover {
  border: 1px solid #0c847e;
  fill: #0c847e;
}
.input-root.selected {
  border: 1px solid #0c847e;
  fill: #0c847e;
}
.input-root.error {
  border: 1px solid red;
  fill: red;
}

.dropdown {
  width: 100%;
  position: relative;
}

.dropdown .autocomplete {
  position: absolute;
  background-color: white;
  width: 100%;
  border-radius: 5px;
  z-index: 1;
}
.dropdown .autocomplete.autocomplete-shadow {
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.35);
}
