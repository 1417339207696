.account-settings-root {
  width: 100%;
}

.account-settings-root .account-paper {
  display: flex;
  background-color: #ffffff;
  padding: 2%;
}

/* Left Column */
.account-settings-root .account-paper .left-col {
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  flex: 1;
}
.account-settings-root .account-paper .left-col .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.account-settings-root .account-paper .left-col .header .logo-sec {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.account-settings-root .account-paper .left-col .header .logo-sec:hover {
  cursor: pointer;
}

.account-settings-root .account-paper .left-col .header .logo-sec .logo {
}
.account-settings-root .account-paper .left-col .header .logo-sec .edit-text {
  padding-left: 5px;
  color: #0c847e;
}

/* Horizontal Line */
.account-settings-root .account-paper .left-col .hr-line {
  width: 100%;
  border: 1px solid #c4c4c4;
  opacity: 1;
}

/* Busines contacts field */
.account-settings-root .account-paper .left-col .contact {
  display: flex;
  flex-direction: column;
  padding-top: 5%;
}
.account-settings-root .account-paper .left-col .contact .heading {
  font-weight: bold;
}
.account-settings-root .account-paper .left-col .contact .desc {
}

/* Right column */
.account-settings-root .account-paper .right-col {
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  flex: 1;
}
.account-settings-root .account-paper .right-col .header {
  display: flex;
  flex-direction: row;
}
.account-settings-root .account-paper .right-col .hr-line {
  width: 100%;
  border: 1px solid #c4c4c4;
  opacity: 1;
}

/* Creds Section */
.account-settings-root .account-paper .right-col .creds-sec {
  padding-top: 5%;
  display: flex;
  flex-direction: column;
}
.account-settings-root .account-paper .right-col .creds-sec .head {
  font-weight: bold;
}
.account-settings-root .account-paper .right-col .creds-sec .head-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.account-settings-root
  .account-paper
  .right-col
  .creds-sec
  .head-row
  .edit-icon-sec {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.account-settings-root
  .account-paper
  .right-col
  .creds-sec
  .head-row
  .edit-icon-sec:hover {
  cursor: pointer;
}
.account-settings-root
  .account-paper
  .right-col
  .creds-sec
  .head-row
  .edit-icon-sec
  .edit-text {
  padding-left: 5px;
  color: #0c847e;
}

@media only screen and (max-width: 600px) {
  .account-settings-root .account-paper {
    flex-direction: column;
  }
}
